

<!---
steps:
1. What is your PayPal Client ID?
2. Important Storefront Details
    - What will your storefront be called? What is your address?
3. What products would you like to sell?
    - Expiry
4. Finalize
    - link for customers
    - link for management
-->
<div class="row">
    <div class="col-md-6">
        <ng-wizard (stepChanged)="stepChanged($event)">

            <ng-wizard-step [title]="'Client ID'" [description]="'What is your PayPal Client ID?'">
                <div class="row">
                    <p class="lead p-2">
                        This is the first and most complicated step, if you can make it through this, we'll be on our way to collecting your customer's orders in
                        no time.
                    </p>
                    <hr class="my-4">
                    <ol>
                        <li>Go to <a href="https://Developer.PayPal.com" target="_blank">Developer.PayPal.com</a> and select <strong>Log into Dashboard</strong> in the right hand corner
                        (you may have to click on the menu dropdown in the upper left-hand corner to see the <strong>Log into Dashboard</strong> link).</li>
                        <li>On the <a href="https://developer.paypal.com/developer/applications" target="_blank">My Apps & Credentials</a> page, select <strong>Live</strong></li>
                        <li>Click on the <strong>Create App</strong> button and name this anything you'd like to manage your online business</li>
                        <li>Copy/paste the Client ID below. We don't need the secret.</li>
                    </ol>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-group">
                            <label class="col-form-label pr-2">Client ID:</label>
                            <input
                                type="text"
                                [(ngModel)]="storefront.clientId"
                                class="form-control col-md-8"
                                placeholder="Client ID"
                                />
                        </div>
                    </div>
                </div>
            </ng-wizard-step>
            <ng-wizard-step [title]="'Details'" [description]="'Collect details about your storefront'">
                <p>
                    Your customers will see your <strong>address</strong> on both the order form, and the reciept so they know where to pick up their product. They'll
                    see the <strong>Store Name</strong> when the come to visit your storefront, but PayPal may show them the name you entered into PayPal as your bisiness
                    name. You may want to make sure they're similar.

                </p>

                <div class="col-md-12">
                    <div class="form-group">
                        <label class="">Store Name</label>
                        <input
                            type="text"
                            [(ngModel)]="storefront.name"
                            class="form-control col-md-6"
                            placeholder="Store Name"
                            />
                    </div>

                    <div class="form-group">
                        <label for="inputAddress">Address</label>
                        <input type="text"
                        class="form-control"
                        id="inputAddress"
                        placeholder="1234 Main St"
                        [(ngModel)]="storefront.address.address_line_1">
                    </div>
                    <div class="form-group">
                        <label for="inputAddress2">Address 2</label>
                        <input type="text" class="form-control" id="inputAddress2"
                        placeholder="Apartment, studio, or floor"
                        [(ngModel)]="storefront.address.address_line_2">
                    </div>
                    <div class="form-row">
                        <div class="form-group col-md-6">
                        <label for="inputCity">City</label>
                        <input type="text" class="form-control" id="inputCity"
                            [(ngModel)]="storefront.address.admin_area_2">
                        </div>
                        <div class="form-group col-md-4">
                        <label for="inputState">State</label>
                        <select id="inputState" class="form-control" [(ngModel)]="storefront.address.admin_area_1">
                            <option selected>Choose...</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        </div>
                        <div class="form-group col-md-2">
                        <label for="inputZip">Zip</label>
                        <input type="text" class="form-control" id="inputZip" [(ngModel)]="storefront.address.postal_code">
                        </div>
                    </div>
                </div>
            </ng-wizard-step>
            <ng-wizard-step [title]="'Products'" [description]="'Enter your products'">
                <div class="col-md-12 mb-3">
                    <div class="row p-1 " *ngFor="let product of storefront.products; index as index">
                        <div class="col">
                            <input type="text"
                                #productNameInput
                                class="form-control"
                                placeholder="Product Name"
                                (blur)="updateProduct(product, productNameInput.value, productPriceInput.value) ">
                        </div>
                        <div class="col-md-3 input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">$</span>
                            </div>
                            <input type="number" #productPriceInput
                                step="1"
                                class="form-control"
                                #productPriceInput
                                aria-label="Amount (to the nearest dollar)"
                                (blur)="updateProduct(product, productNameInput.value, productPriceInput.value) ">
                            <div class="input-group-append">
                                <span class="input-group-text">.00</span>
                            </div>
                        </div>
                        <div class="col-md-2 pt-1">
                            <button type="button" class="btn btn-danger btn-sm" (click)="removeProduct(index)">Remove Item</button>
                        </div>
                    </div>
                </div>

                <button type="button" (click)="addProduct()" class="btn btn-primary btn-lg btn-block mb-4">
                    Add Product
                </button>
            </ng-wizard-step>

            <ng-wizard-step [title]="'Finished'" [description]="'Share your Storefront'">
                <p>
                    Before you start sending your storefront link to your customers, there's a few things you must know:
                </p>
                <ul>
                    <li>
                        As you may have noticed, <em>we don't manage inventory</em>: if your run out of product, it's <em>your responsibility</em> to refund
                        your customer approprietly. PayPal offers soluitions to do this.
                    </li>
                    <li>
                        Your link and it's products are always static: meaning once this link is distributed, your customer can use it <strong>indefinitely</strong>
                    </li>
                    <li>
                        However, your storefront will <em>expire after the date of your chosing below</em>. By default, this is a month from right now. This is the
                        <strong>only way</strong> your storefront here will cease to operate.  Customers can bookmark your storefront, save the link, or
                        anything, and it will remain.  This might be okay if you have replenished inventory, and a constant menu -- but if you're inventory limited,
                         you may want to make this date shorter.
                    </li>
                </ul>

                <div class="form-group row">
                    <label class="col-md-2 pt-1">Expiry Date</label>
                    <input
                        type="date"
                        [(ngModel)]="storefront.expires"
                        class="form-control col-md-6"
                        placeholder="Store Name"
                        />
                </div>

                <a routerLink="/storefront" [(queryParams)]="encodedStorefront" class="btn btn-secondary btn-lg btn-block mb-4">
                    Link to your Storefront
                </a>
            </ng-wizard-step>
        </ng-wizard>
    </div>
    <div class="col-md-6">
        <h3>Preview</h3>
        <div class="card">
            <div class="card-body">
                <app-storefront-display [(storefront)]="storefront"></app-storefront-display>
            </div>
        </div>
    </div>

</div>
<!-- <pre>{{encodedStorefront|json}}</pre> -->