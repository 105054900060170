<div class="jumbotron">
    <h1 class="display-4">Storefront Creator</h1>
    <p class="lead">
        We're here to make collecting customer's pickup orders easier, while reducing your physical customer contact in a low-cost, high-tech manner.
    </p>
    <hr class="my-4">
    <p>
        We're trying to enable small businesses to make their products available online with little upfront
        cost or technical ability. We understand that the resposne to COVID-19 has made it dificult for small
        businesses to keep their doors open, and we want to keep it easy for them to do so.
        <strong>Pickup orders</strong> are the new norm, and so is minimizing customer contact. We're here to help.
    </p>
    <p>
        Our mission starts with making this product <i>easy to understand</i> and <i>useful</i>. If we can do anything to
        make this product better, please email <a href="mailto:eze@brk.dev">eze@brk.dev</a>.
    </p>
    <p class="lead">
        <a class="btn btn-primary btn-lg" a routerLink="/create-storefront"  role="button">Create a Storefront</a>
    </p>
</div>