<nav class="navbar navbar-dark bg-dark mb-3">
  <a routerLink="/" routerLinkActive="active" class="nav-item navbar-brand">Easy Ecommerse</a>
  <a routerLink="/create-storefront" routerLinkActive="active" class="nav-item">Create your own storefront</a>
  <a href="https://github.com/vertex/eze" target="_blank" class="nav-item">Contribute on GitHub</a>
</nav>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>